import "@unocss/reset/tailwind-compat.css";
import "virtual:uno.css";
import "~/assets/css/sentry.css";

import { Toast } from "@kobalte/core";
import type { RouteSectionProps } from "@solidjs/router";
import { Router } from "@solidjs/router";
import { FileRoutes } from "@solidjs/start/router";
import { Suspense } from "solid-js";
import { DataStoreProvider } from "./components/DataStore";
import Navbar from "./components/Navbar";
import { ThemeProvider } from "./components/ThemeController";

// const SentryRouter = withSentryRouterRouting(Router);
// dark:bg-neutral-900

const Root = (props: RouteSectionProps) => {
  return (
    <ThemeProvider>
      <DataStoreProvider>
        <Suspense>
          <Navbar />
          {props.children}
          <Toast.Region>
            <Toast.List class="fixed bottom-0 right-0 z-9999 max-w-full w-100 flex flex-col gap-2 p-4 outline-none" />
          </Toast.Region>
        </Suspense>
      </DataStoreProvider>
    </ThemeProvider>
  );
};

export default function App() {
  return (
    <Router root={Root}>
      <FileRoutes />
    </Router>
  );
}
